<script setup lang="ts">
const colorMode = useColorMode()

const { comma } = useUi()
const { url } = useImageStorage()

const { userData } = storeToRefs(useUserDataStore())

withDefaults(
  defineProps<{
    menuList?: Database['public']['Tables']['menuList']['Row'][]
  }>(),
  {
    menuList: () => ([] as Database['public']['Tables']['menuList']['Row'][]),
  },
)

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})

const avatarUrl = ref(url(true, '/assets/wnm_logo.svg'))

if (userData.value?.avatar_id) {
  userData.value.avatarList.map(async (avatar) => {
    if (avatar.id === userData.value?.avatar_id) {
      avatarUrl.value = avatar.url ?? ''
    }
  })
}

const navigateMyProfile = () => {
  navigateTo('/profile')
  menuOpenTrigger.value = false
}

const navigateMyMenu = (to: string) => {
  navigateTo(to)
  menuOpenTrigger.value = false
}

const navigatePoint = (type: string) => {
  type === 'charge'
    ? navigateTo('/point/charge')
    : navigateTo('/point/exchange')

  menuOpenTrigger.value = false
}
</script>

<template>
  <WMCard :ui="{ header: { padding: 'px-3 py-3 sm:px-3' } }">
    <template #header>
      <div class="flex flex-col gap-y-4">
        <div
          class="w-full flex items-center gap-4 cursor-pointer hover:text-neutral-500 hover:rounded-lg transition ease-in-out"
          @click="navigateMyProfile"
        >
          <AUserAvatar
            :show-chip="userData?.podium_point ? true : false"
            :podium-point="userData?.podium_point ?? 0"
            :avatar-url="avatarUrl"
          />
          <div class="flex flex-col">
            <span class="text-xl font-semibold">
              {{ $t('menu.sir', { nickname: userData?.nickname ?? '유저' }) }}
            </span>
            <span class="text-sm">
              {{ userData?.email ?? '이메일을 설정해주세요.' }}
            </span>
          </div>
          <div class="flex-auto" />
          <Icon
            name="i-ri-arrow-drop-right-line"
            class="w-8 h-8"
          />
        </div>
        <div class="w-full flex justify-end items-center gap-x-12 pr-4">
          <span class="text-xl">
            {{ $t('text.point') }}
          </span>
          <span class="text-base">
            {{ $t('text.pointAmount', { point: comma(userData?.point ?? 0) }) }}
          </span>
        </div>
        <div class="w-full flex justify-center gap-x-4 px-2">
          <AButton
            custom-class="w-full ring-neutral-200"
            button-block
            button-variant="outline"
            button-size="md"
            use-leading
            icon-lead-name="i-ri-add-circle-line"
            icon-lead-class="w-5 h-5"
            :button-text="$t('button.charge')"
            @click="navigatePoint('charge')"
          />
          <AButton
            custom-class="w-full ring-neutral-200"
            button-block
            button-variant="outline"
            button-size="md"
            use-leading
            icon-lead-name="i-ri-exchange-dollar-line"
            icon-lead-class="w-5 h-5"
            :button-text="$t('button.exchangeMonet')"
            @click="navigatePoint('exchange')"
          />
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-y-4">
      <div class="flex items-center gap-x-1">
        <span class="text-base">
          {{ $t('text.my') }}
        </span>
        <NuxtImg
          :src="url(true, colorMode.value === 'dark' ? '/assets/wnm_horizontal_logo_text_dark.svg' : '/assets/wnm_horizontal_logo_text.svg')"
          :width="158"
        />
      </div>
      <div class="flex flex-wrap justify-between gap-y-2">
        <AButton
          v-for="(menu, index) in menuList"
          :key="index"
          class="w-[calc(50%-0.5rem)]"
          button-variant="ghost"
          use-leading
          :icon-lead-name="menu.icon_name ?? ''"
          icon-lead-class="w-4 h-4"
          :button-text="menu.title ?? ''"
          @click="navigateMyMenu(menu.to ?? '')"
        />
      </div>
    </div>
  </WMCard>
</template>
