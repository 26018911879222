<script setup lang="ts">
const client = useSupabaseClient()

const { userData } = storeToRefs(useUserDataStore())
const {
  integratedNotification,
  newOwnerExpertMessage,
  newOwnerHostMessage,
  newCoachingExpertMessage,
  newCoachingHostMessage,
  newRepairExpertMessage,
  newRepairHostMessage,
  newRescueExpertMessage,
  newRescueHostMessage,
  newPickupExpertMessage,
  newPickupHostMessage,
} = storeToRefs(useNotificationDataStore())

const { url } = useImageStorage()

defineEmits([
  'click:menu-open',
])

const updateNotificationStatus = (userType: 'expert' | 'host', meetingType: 'MTC001' | 'MTC002' | 'MTC003' | 'MTC004' | 'MTC005') => {
  if (userType === 'expert') {
    switch (meetingType) {
      case 'MTC001':
        newOwnerExpertMessage.value = true
        break
      case 'MTC002':
        newCoachingExpertMessage.value = true
        break
      case 'MTC003':
        newRepairExpertMessage.value = true
        break
      case 'MTC004':
        newRescueExpertMessage.value = true
        break
      case 'MTC005':
        newPickupExpertMessage.value = true
        break
    }
  }
  else {
    switch (meetingType) {
      case 'MTC001':
        newOwnerHostMessage.value = true
        break
      case 'MTC002':
        newCoachingHostMessage.value = true
        break
      case 'MTC003':
        newRepairHostMessage.value = true
        break
      case 'MTC004':
        newRescueHostMessage.value = true
        break
      case 'MTC005':
        newPickupHostMessage.value = true
        break
    }
  }
}

const listenOwnerMeeting = client
  .channel('ownerMeeting')
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'ownerMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC001')
      }
    },
  )
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'ownerMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC001')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'ownerMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC001')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'ownerMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC001')
      }
    },
  )
  .subscribe()

const listenCoachingMeeting = client
  .channel('coachingMeeting')
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'coachingMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC002')
      }
    },
  )
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'coachingMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC002')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'coachingMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC002')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'coachingMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC002')
      }
    },
  )
  .subscribe()

const listenRepairMeeting = client
  .channel('repairMeeting')
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'repairMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC003')
      }
    },
  )
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'repairMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC003')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'repairMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC003')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'repairMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC003')
      }
    },
  )
  .subscribe()

const listenRescueMeeting = client
  .channel('rescueMeeting')
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'rescueMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC004')
      }
    },
  )
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'rescueMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC004')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'rescueMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC004')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'rescueMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC004')
      }
    },
  )
  .subscribe()

const listenPickupMeeting = client
  .channel('pickupMeeting')
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'pickupMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC005')
      }
    },
  )
  .on('postgres_changes',
    { event: 'INSERT', schema: 'chat_channel', table: 'pickupMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC005')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'pickupMeeting', filter: `host_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_expert_message) {
        updateNotificationStatus('expert', 'MTC005')
      }
    },
  )
  .on('postgres_changes',
    { event: 'UPDATE', schema: 'chat_channel', table: 'pickupMeeting', filter: `expert_user_id=eq.${userData.value?.id}` },
    (payload: any) => {
      if (payload.new.new_host_message) {
        updateNotificationStatus('host', 'MTC005')
      }
    },
  )
  .subscribe()

onUnmounted(() => {
  listenOwnerMeeting.unsubscribe()
  listenCoachingMeeting.unsubscribe()
  listenRepairMeeting.unsubscribe()
  listenRescueMeeting.unsubscribe()
  listenPickupMeeting.unsubscribe()
})
</script>

<template>
  <div class="h-11 flex items-center gap-2">
    <AButton
      button-variant="ghost"
      use-leading
      icon-lead-name="i-ri-menu-2-fill"
      @click="() => $emit('click:menu-open')"
    />
    <NuxtImg
      class="w-10 h-10 cursor-pointer"
      :src="url(true, '/assets/wnm_logo.svg')"
      @click="navigateTo('/')"
    />
    <div class="flex-auto" />
    <AChipButton :enable-chip="integratedNotification" />
    <AButton
      button-variant="ghost"
      use-leading
      icon-lead-name="i-ri-search-2-line"
    />
  </div>
</template>
