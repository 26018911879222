<script setup lang="ts">
const user = useSupabaseUser()

withDefaults(
  defineProps<{
    menuData?: Database['public']['Tables']['menuCategory']['Row'][]
  }>(),
  {
    menuData: () => ([] as Database['public']['Tables']['menuCategory']['Row'][]),
  },
)

defineEmits([
  'click:menu',
])

const visibleSubMenu = (index: number) => {
  switch (index) {
    case 8:
      return user.value?.id ? true : false
    default:
      return index > 8
  }
}
</script>

<template>
  <WMAccordion
    :items="menuData"
    :ui="{ item: { padding: 'p-0' } }"
  >
    <template #default="{ item, index }">
      <WMButton
        v-show="visibleSubMenu(index)"
        color="gray"
        variant="ghost"
        :ui="{ rounded: 'rounded-none', padding: { sm: 'p-3' } }"
        @click="() => $emit('click:menu', item.menuList.length, item.to)"
      >
        <template #leading>
          <Icon
            v-if="item.icon_name"
            class="w-6 h-6"
            :name="item.icon_name"
          />
        </template>
        <span class="text-md turncate">
          {{ item.title }}
        </span>
      </WMButton>
    </template>
  </WMAccordion>
</template>
