<script setup lang="ts">
const menuOpenTrigger = ref(false)
</script>

<template>
  <div class="w-full h-fit sticky top-0 py-2 px-2 bg-neutral-50/40 dark:bg-neutral-950/40 z-10">
    <HeaderNavigationBar @click:menu-open="menuOpenTrigger = !menuOpenTrigger" />
    <HeaderMenu v-model:menu-open-trigger="menuOpenTrigger" />
  </div>
</template>
