<script setup lang="ts">
withDefaults(
  defineProps<{
    menuData?: Database['public']['Tables']['menuCategory']['Row'][]
  }>(),
  {
    menuData: () => ([] as Database['public']['Tables']['menuCategory']['Row'][]),
  },
)

defineEmits([
  'click:menu',
])

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})
</script>

<template>
  <WMAccordion
    :items="menuData"
    :ui="{ item: { padding: 'p-0' } }"
  >
    <template #default="{ item, index, open }">
      <WMButton
        v-show="index > 0 && index < 8"
        color="gray"
        variant="ghost"
        :ui="{ rounded: 'rounded-none', padding: { sm: 'p-3' } }"
        @click="() => $emit('click:menu', item.menuList.length && item.code !== 'setting', item.to)"
      >
        <template #leading>
          <Icon
            v-if="item.icon_name"
            class="w-6 h-6"
            :name="item.icon_name"
          />
        </template>
        <span class="text-md turncate">
          {{ item.title }}
        </span>
        <template #trailing>
          <Icon
            v-if="item.menuList.length && item.code !== 'setting'"
            name="i-ri-arrow-drop-down-line"
            class="w-6 h-6 ms-auto transform transition-transform duration-200"
            :class="[open && 'rotate-180']"
          />
        </template>
      </WMButton>
    </template>
    <template #item="{ item }">
      <WMVerticalNavigation
        v-if="item.menuList.length && item.code !== 'setting'"
        :links="item.menuList"
        :ui="{ active: 'text-green-500', badge: { size: 'md' } }"
        @click="() => menuOpenTrigger = false"
      >
        <template #default="{ link }">
          <div class="h-9 flex justify-center items-center gap-1 pl-6 group-hover:text-primary relative">
            <Icon
              class="w-5 h-5"
              :name="link.icon_name"
            />
            <span class="text-sm">
              {{ link.title }}
            </span>
          </div>
        </template>
      </WMVerticalNavigation>
    </template>
  </WMAccordion>
</template>
