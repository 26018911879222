<script setup lang="ts">
const user = useSupabaseUser()

const { executeUserData } = useLoadUserData()

const { userData } = storeToRefs(useUserDataStore())
const { menuData } = storeToRefs(useMenuDataStore())

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  default: false,
})

const navigateCategoryUrl = (children: boolean, to: string) => {
  if (!children) {
    navigateTo(to)
    menuOpenTrigger.value = false
  }
}

watch(menuOpenTrigger, async () => {
  if (menuOpenTrigger.value) {
    await executeUserData()
  }
})
</script>

<template>
  <WMSlideover
    v-model="menuOpenTrigger"
    overlay
    side="left"
    :ui="{ base: 'overflow-y-scroll' }"
  >
    <div class="w-full flex flex-col pt-2 px-3 pb-8 gap-y-8">
      <div class="flex justify-end h-8">
        <AButton
          use-leading
          button-variant="ghost"
          icon-lead-name="i-ri-close-line"
          :tooltip-text="$t('button.close')"
          @click="() => menuOpenTrigger = false"
        />
      </div>
      <LoginButton
        v-if="!user"
        class="max-w-[332px]"
        @click:login="() => menuOpenTrigger = false"
      />
      <MenuMy
        v-else-if="userData && menuData"
        v-model:menu-open-trigger="menuOpenTrigger"
        :menu-list="menuData[0]?.menuList"
      />
      <MenuCategory
        v-model:menu-open-trigger="menuOpenTrigger"
        :menu-data="menuData"
        @click:menu="(isChildren: boolean, to: string) => navigateCategoryUrl(isChildren, to)"
      />
      <WMDivider />
      <div class="flex flex-col gap-y-0.5">
        <MenuSubCategory
          :menu-data="menuData"
          @click:menu="(isChildren: boolean, to: string) => navigateCategoryUrl(isChildren, to)"
        />
        <MenuAuthButton
          v-if="user"
          v-model:menu-open-trigger="menuOpenTrigger"
        />
      </div>
    </div>
  </WMSlideover>
</template>
